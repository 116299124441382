import React, { Component } from "react";
import classes from "./signupmess.module.css";
import SideDrawer from "../SideDrawer/SideDrawer";

class SignupMess extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }
  render() {
    return (
      <div className={classes.background} style={{backgroundColor: "#1e1762"}}>
        <SideDrawer />
        <div className={classes.msgWrapper}>
          <div className={classes.regLine}>You have been registered for</div>
          <div className={classes.ktjName}>
            KSHITIJ <span className={classes.year}>2021</span>.
          </div>
          <div className={classes.Idbox}>
            <span className={classes.idIntial}>Your KTJ-ID is </span>{" "}
            <span className={classes.ktjId}> { this.props.location.state.ktjID } </span>
          </div>
          <div className={classes.note}>
            Please note it for future reference
          </div>
          <div className={classes.explore}>Explore KTJ</div>
        </div>
      </div>
    );
  }
}

export default SignupMess;

// const SignupMess = () => {
//   return (
//     <div className={classes.background}>
//       {/* <Navbar /> */}
//       <div className={classes.msgWrapper}>
//         <div className={classes.regLine}>You have been registered for</div>
//         <div className={classes.ktjName}>
//           KSHITIJ <span className={classes.year}>2021</span>.
//         </div>
//         <div className={classes.Idbox}>
//           <span className={classes.idIntial}>Your KTJ-ID is </span>{" "}
//           <span className={classes.ktjId}> KTJ ID </span>
//         </div>
//         <div className={classes.note}>Please note it for future reference</div>
//         <div className={classes.explore}>Explore KTJ</div>
//       </div>
//     </div>
//   );
// };

// export default SignupMess;
