import React, { Component } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import classes from "./Schedule2.module.css";
import TataProject from "../../images/Tata.png";
import ScheduleCard from "../../Components/ScheduleCard/ScheduleCard";
import SideDrawer from "../../mobile/src/Components/SideDrawer/SideDrawer";

// Importing images
import mcafeee from "./images/mcaffee.png";
import SourceCode from "./images/Source-code.png";
import CodeOSoccer from "./images/Code-O-soccer.png";
import Biomimicry from "./images/Biomimicry.png";
import TechSummit from "./images/s_nandan.jpg";
import Snappit from "./images/Snappit.png";
import Cat from "./images/Cat.jpg";
import Goose from "./images/Goose.jpg";
import line from "../../Components/Events/img/sponsors/line-80px.png";
import siemens from "./images/siemens.PNG";
const synopsys =
  "https://github.com/KSHITIJ-2021/sponsors_images/blob/main/Sponsors2020/Event%20Sponsors/Synopsys/synopsys.png?raw=true";
const kla =
  "https://github.com/KSHITIJ-2021/sponsors_images/blob/main/Sponsors2020/Event%20Sponsors/KLA/kla.png?raw=true";
const jsl =
  "https://github.com/KSHITIJ-2021/sponsors_images/blob/main/Sponsors2021/Event%20sponsor/jsl-logo-f%20(2).jpg?raw=true";
const mouser =
  "https://github.com/KSHITIJ-2021/sponsors_images/blob/main/Sponsors2021/Event%20sponsor/m-mouserelectronics-horizontal-fullcolor-blue.png?raw=true";
import ReactGa from "react-ga";
const breakingpoint = 876;

class Schedule extends Component {
  state = {
    CurrentDate: "30",
    EventDates: ["30", "31"],
    MajorCardData: {
      Host: "TECH TALK",
      Timing: "11:00am",
      About:
        "Mr. Somnath, Vice President and Head of Engineering at Tata Projects Limited",
      Image: TechSummit,
      link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_N2QyZmZjYjItOGFhNi00MTYzLTg0OTItYTEwZTY5Njc5NzU0%40thread.v2/0?context=%7b%22Tid%22%3a%2271dbb522-5704-4537-9f25-6ad2dcd4278d%22%2c%22Oid%22%3a%22da72f447-6125-4463-ac84-7cb8c3a6b19a%22%2c%22IsBroadcastMeeting%22%3atrue%7d",
    },
    GuestLectures: {
      30: [
        {
          event: "Event",
          Host: "Source Code",
          company: "",
          Timing: "2:00pm - 4:00pm ",
          About:
            "The main objective of SourceCode is to identify the best coder who can guess the problem statement by his/her intuition.",
          Image: SourceCode,
          link: "",
        },
        {
          event: "Event",
          Host: "Code-O-Soccer",
          company: "",
          Timing: "2:00pm - 4:00pm ",
          About:
            "Compete with your coding and strategy making skills in this 3v3 simulation football event.",
          Image: CodeOSoccer,
          link: "",
        },
        {
          event: "Event",
          Host: "Biomimicry",
          company: "",
          Timing: "2:00pm - 4:00pm ",
          About:
            "Participants are invited to come up with innovative technological solutions to pioneer the domain of nature",
          Image: Biomimicry,
          link: "",
        },

        // {
        //   event: "Summit",
        //   Host: "Virtual Technology Summit",
        //   company: "",
        //   Timing: "11:00am",
        //   About:
        //     "Mr. Somnath Nandan heads the engineering and design of urban infrastructure business in Tata Projects Limited in Mumbai",
        //   Image: TechSummit,
        //   link: "http://bit.ly/3ck4XR1",
        // },
        {
          event: "Workshop",
          Host: "Malware Trends And Analysis",
          company: "McAfee",
          Timing: "1:00pm - 3:00pm ",
          About:
            '"Malware Trends & Analysis", by McAfee, where participants will be introduced to different types of malware, the current threat landscapes and the basics of how researchers analyze a malware',
          Image: mcafeee,
          link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MmQxMTllYmMtMTI2Ni00MWQyLWI0NGUtMzAyZjBjNGFlMzE3%40thread.v2/0?context=%7b%22Tid%22%3a%2271dbb522-5704-4537-9f25-6ad2dcd4278d%22%2c%22Oid%22%3a%22da72f447-6125-4463-ac84-7cb8c3a6b19a%22%2c%22IsBroadcastMeeting%22%3atrue%7d",
        },
      ],
      31: [
        {
          event: "Event",
          Host: "Snappit",
          company: "",
          Timing: "2:00pm - 4:00pm ",
          About:
            "Snappit is an event in which participants will have to show their photography skills",
          Image: Snappit,
          link: "",
        },
        {
          event: "Tech Talk",
          Host: "Siemens",
          company: "",
          Timing: "11:00am - 1:00pm ",
          About:
            "This talk will first introduce some common concepts of (ASIC) design world like design flow, verification, emulation. It will then talk about common trends in design verification and how emulation has evolved its role in design verification and validation.",
          Image: siemens,
          link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWY3YjE5MDAtNzBiYy00YTI1LWEwNjAtOWYyYzlkZTJmOTdm%40thread.v2/0?context=%7b%22Tid%22%3a%2271dbb522-5704-4537-9f25-6ad2dcd4278d%22%2c%22Oid%22%3a%22da72f447-6125-4463-ac84-7cb8c3a6b19a%22%2c%22IsBroadcastMeeting%22%3atrue%7d",
        },
        {
          event: "Workshop",
          Host: "Siemens",
          company: "",
          Timing: "4:00pm - 6:00pm ",
          About:
            "Rapid Application Development in Cloud- Zero to Hero in 20 Hours!, A rapid application development model provides a faster path to turn your business ideas into working applications. The new approach relies on cloud software that removes infrastructure distractions so that users can focus on delivering the right solution within a shorter timeline, and achieve ROI faster. ",
          Image: siemens,
          link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTE0NTQxYjgtZTNlYi00YWM1LWJkMzQtNTY0YTViZjI1ZmQ5%40thread.v2/0?context=%7b%22Tid%22%3a%2271dbb522-5704-4537-9f25-6ad2dcd4278d%22%2c%22Oid%22%3a%22da72f447-6125-4463-ac84-7cb8c3a6b19a%22%2c%22IsBroadcastMeeting%22%3atrue%7d",
        },
      ],
    },
    windowwidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    if (
      (window.innerWidth - breakingpoint) *
        (this.state.windowwidth - breakingpoint) <
      0
    )
      this.setState({ windowwidth: window.innerWidth });
  };
  render() {
    let Navigation = <Navbar Bgcolor={true} />;
    let isMobile = false;
    if (this.state.windowwidth < breakingpoint) {
      Navigation = <SideDrawer />;
      isMobile = true;
    }
    const { GuestLectures, CurrentDate } = this.state;
    return (
      <div className={classes.Schedule}>
        {Navigation}
        <div className={classes.Schedule_Body}>
          <div className={classes.Schedule_Heading}>Phase 2</div>
          <div className={classes.presented}>Presented By</div>
          <div className={classes.Schedule_TitleImage}>
            <img src={TataProject} alt="tataproject" />
          </div>

          <div className={classes.Schedule_Divider}>
            <img className={classes.imagecnt} src={line} />
            <img className={classes.imagecnt} src={synopsys} />
            <img className={classes.imagecnt} src={kla} />
            <img className={classes.imagecnt} src={mouser} />
            <img className={classes.imagecnt} src={jsl} />
            <img className={classes.imagecnt} src={siemens} />
          </div>
          <div className={classes.workshops}>
            <div className={classes.workshops_row}>Events</div>
            <div className={classes.workshops_images}>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/SourceCode"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked Source Code in schedule page ",
                    });
                  }}
                >
                  <img src={SourceCode} />
                  <br />
                  Source Code
                </a>
              </div>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/CodeOSoccer"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked ode-O-Soccer in schedule page ",
                    });
                  }}
                >
                  <img src={CodeOSoccer} />
                  <br />
                  Code-O-Soccer
                </a>
              </div>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/Biomimicry"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked Biomimicry in schedule page ",
                    });
                  }}
                >
                  <img src={Biomimicry} />
                  <br />
                  Biomimicry
                </a>
              </div>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/Snappit"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked Snappit in schedule page ",
                    });
                  }}
                >
                  <img src={Snappit} />
                  <br />
                  Snappit
                </a>
              </div>
            </div>
          </div>
          <div className={classes.workshops}>
            <div className={classes.workshops_row}>Workshop</div>
            <div className={classes.workshops_images}>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/activities/workshops/Malware%20trends%20and%20analysis"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action:
                        "Clicked Malware trends and analysis in schedule page ",
                    });
                  }}
                >
                  <img src={mcafeee} />
                  <br />
                  Malware trends and analysis
                </a>
              </div>
              <div className={classes.workshops_image}>
                <a
                  href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTE0NTQxYjgtZTNlYi00YWM1LWJkMzQtNTY0YTViZjI1ZmQ5%40thread.v2/0?context=%7b%22Tid%22%3a%2271dbb522-5704-4537-9f25-6ad2dcd4278d%22%2c%22Oid%22%3a%22da72f447-6125-4463-ac84-7cb8c3a6b19a%22%2c%22IsBroadcastMeeting%22%3atrue%7d"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action:
                        "Clicked Malware trends and analysis in schedule page ",
                    });
                  }}
                >
                  <img src={siemens} />
                  <br />
                  Rapid Application Development
                </a>
              </div>
            </div>
          </div>
          <div className={classes.workshops}>
            <div className={classes.workshops_row}>Gamefest</div>
            <div className={classes.workshops_images}>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/gamefest"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked Gamefest in schedule page ",
                    });
                  }}
                >
                  <img src={Cat} />
                  <br />
                  Catalysm
                </a>
              </div>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/gamefest"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked Gamefest in schedule page ",
                    });
                  }}
                >
                  <img src={Goose} />
                  <br />
                  Goosebumps
                </a>
              </div>
            </div>
          </div>
          <div className={classes.workshops}>
            <div className={classes.workshops_row}>Summits</div>
            <div className={classes.workshops_images}>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/summit"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked Gamefest in schedule page ",
                    });
                  }}
                >
                  <img src={TechSummit} />
                  <br />
                  Tech Talk by Mr. Somnath Nandan
                </a>
              </div>
              <div className={classes.workshops_image}>
                <a
                  href="https://ktj.in/#/events/summit"
                  target="_blank"
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action: "Clicked Gamefest in schedule page ",
                    });
                  }}
                >
                  <img src={siemens} />
                  <br />
                  Design verification trends, the role of emulation
                </a>
              </div>
            </div>
          </div>
          <div className={classes.Schedule_DateTry}>
            {this.state.EventDates.map((date) => {
              return (
                <div
                  className={`${classes.Schedule_DateTry_DateTab}`}
                  style={
                    CurrentDate === date
                      ? {
                          backgroundColor: " #474072",
                          border: "2px solid #474072",
                        }
                      : {}
                  }
                  onClick={() => {
                    ReactGa.event({
                      category: "Click",
                      action:
                        "Clicked the " + date + " date tab in Schedule page",
                    });
                    this.setState({ CurrentDate: date });
                  }}
                >
                  {date == 30 ? date + "th" : date + "st"}
                </div>
              );
            })}
          </div>
          <div
            className={classes.HowToUseAirmeet}
            onClick={() => {
              ReactGa.event({
                category: "Click",
                action:
                  "Clicked the How to use airmeet button in schedule page ",
              });
            }}
          >
            <a
              href="https://www.youtube.com/watch?v=Z7IiXgiVLXg"
              target="_blank"
            >
              How to Use Airmeet{" "}
            </a>
          </div>

          <div className={classes.Schedule_CardsContainer}>
            {!isMobile ? (
              <div className={classes.Schedule_MainTataProjectCardCnt}>
                <div className={classes.Schedule_majorCard}>
                  <div className={classes.Schedule_majorCardImageCnt}>
                    <img
                      className={classes.Schedule_majorCardImageCnt}
                      src={TechSummit}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        textAlign: "center",
                        transform: "translateY(-50%)",
                      }}
                    >
                      TECH TALK
                    </div>
                    <div style={{ textAlign: "center", fontSize: "0.8em" }} className={classes.majorCardDate}>30-01-2021</div>
                    <div style={{ textAlign: "center", fontSize: "0.8em" }} className={classes.majorCardTime}>11:00am</div>
                    <div style={{ textAlign: "center", fontSize: "0.8em" }}><div style={{ textAlign: "center", fontSize: "1.2em" }}><b>Mr. Somnath Nandan</b></div> Vice President and Head of Engineering at Tata Projects Limited</div>

                    <a
                      onClick={() => {
                        ReactGa.event({
                          category: "Click",
                          action: "Clicked Join Tech Talk in schedule",
                        });
                      }}
                      // href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_N2QyZmZjYjItOGFhNi00MTYzLTg0OTItYTEwZTY5Njc5NzU0%40thread.v2/0?context=%7b%22Tid%22%3a%2271dbb522-5704-4537-9f25-6ad2dcd4278d%22%2c%22Oid%22%3a%22da72f447-6125-4463-ac84-7cb8c3a6b19a%22%2c%22IsBroadcastMeeting%22%3atrue%7d"
                      target="_blank"
                      className={classes.joinbtn}
                    >
                      Closed
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={classes.Schedule_CardsContainer_left}>
              {isMobile ? <ScheduleCard {...this.state.MajorCardData} /> : null}

              {GuestLectures[CurrentDate].map((Lecture) => {
                return <ScheduleCard {...Lecture} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Schedule;
