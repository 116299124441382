import React from "react";
import Navigation from "../Navbar/Navbar";
import Classes from "./Coming.module.css";
import back_video from "./Back_gif/Coming soon.mp4";
import Vdo from "../../Components/vdoplayer/Vdo";
import ReactGa from "react-ga";
class Coming extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    ReactGa.event({
      category: "Click",
      action: "Clicked on Back in ComingSoonpage",
    });
    this.props.history.goBack();
  }
  render() {
    return (
      <div style={{ backgroundColor: "#1e1762" }}>
        <Navigation />
        <Vdo name={back_video} loopCondition={true} />
        <button onClick={this.goBack} className={Classes.back_button}>
          Back
        </button>
      </div>
    );
  }
}
export default Coming;
