import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classes from "./Navbar.module.css";
import ktjlogo from "../../images/ktjLogo.png";
import { logoutUser } from "../../actions/authActions";
import ReactGa from "react-ga";
class Navbar extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }
  render() {
    let SideDrawerBtnClass = [classes.SideDrawerBtn, classes.Hide];

    if (!this.props.showSideDrawer) {
      SideDrawerBtnClass = [classes.SideDrawerBtn, classes.Show];
    }

    const { isAuthenticated, userInfo } = this.props.auth;

    const logoutUser = (e) => {
      e.preventDefault();
      this.props.logoutUser(userInfo, this.props.history.push("/signin"));
    };

    return (
      <div
        className={classes.Navbar}
        style={{
          backgroundImage: this.props.Bgcolor
            ? `linear-gradient(
        to right,
        #150f5d8f 15%,
    #2a236a8f 20%,
    #2a236a8f 75%,
    #150f5d8f 80%
      )`
            : null,
        }}
        onClick={(event) => {
          ReactGa.event({
            category: "Click",
            action:
              "Clicked the header " +
              (event.target.innerText ? event.target.innerText : ""),
          });
        }}
      >
        <div
          className={SideDrawerBtnClass.join(" ")}
          onClick={this.props.toggleSideDrawer}
        >
          <i className="fa fa-bars"></i>
        </div>
        <NavLink
          onClick={() => {
            window.scroll({ left: 0, top: 0, behavior: "smooth" });
          }}
          to="/"
          style={{ color: " #c4b9e2", textDecoration: "none" }}
        >
          <img src={ktjlogo} height="120" width="180" alt="Kshitij" />
        </NavLink>

        <ul
          className={classes.NavbarList}
          style={this.props.styles ? { ...this.props.styles } : null}
        >
          <li>
            <NavLink
              activeClassName={classes.active}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/schedule"
              style={{ color: " #c4b9e2", textDecoration: "none" }}
              exact
            >
              Schedule
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={classes.active}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/events"
              style={{ color: " #c4b9e2", textDecoration: "none" }}
              exact
            >
              Events{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={classes.active}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/activities/workshops"
              style={{ color: " #c4b9e2", textDecoration: "none" }}
              exact
            >
              Workshops{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={classes.active}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/activities"
              style={{ color: " #c4b9e2", textDecoration: "none" }}
              exact
            >
              Activities
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={classes.active}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/sponsors"
              style={{ color: " #c4b9e2", textDecoration: "none" }}
              exact
            >
              Sponsors
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={classes.active}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/contactus"
              style={{ color: " #c4b9e2", textDecoration: "none" }}
              exact
            >
              Contact Us
            </NavLink>
          </li>

          {/* <div>
            {!isAuthenticated ? (
              <li>
                <NavLink
                  activeClassName={classes.active}
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/signin"
                  style={{ color: " #c4b9e2", textDecoration: "none" }}
                  exact
                >
                  <button className={classes.SignInBtn}>Sign In</button>
                </NavLink>
              </li>
            ) : null}
            {!isAuthenticated ? (
              <li>
                <NavLink
                  activeClassName={classes.active}
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/signup"
                  style={{ color: " #c4b9e2", textDecoration: "none" }}
                  exact
                >
                  <button className={classes.SignUpBtn}>Sign Up</button>
                </NavLink>
              </li>
            ) : null}
            {isAuthenticated ? (
              <li>
                <NavLink
                  activeClassName={classes.active}
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/profile"
                  style={{ color: " #c4b9e2", textDecoration: "none" }}
                  exact
                >
                  <button className={classes.ProfileBtn}>Profile</button>
                </NavLink>
              </li>
            ) : null}
            {isAuthenticated ? (
              <li>
                <NavLink
                  activeClassName={classes.active}
                  onClick={logoutUser}
                  to="signin"
                  style={{ color: " #c4b9e2", textDecoration: "none" }}
                  exact
                >
                  <button>Logout</button>
                </NavLink>
              </li>
            ) : null}
          </div> */}
        </ul>
      </div>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Navbar));
