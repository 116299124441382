import React from "react";
import Vdo from "../../Components/vdoplayer/Vdo";
import bgvdo from "./back_video/Coming soon mobile.mp4";
import Classes from "./Coming.module.css";
import SideDrawer from "../SideDrawer/SideDrawer";
import ReactGa from "react-ga";
class Coming extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    ReactGa.event({
      category: "Click",
      action: "Clicked on Back in ComingSoonpage",
    });
    this.props.history.goBack();
  }
  render() {
    return (
      <div style={{ backgroundColor: "#1e1762" }}>
        <SideDrawer />
        <Vdo name={bgvdo} loopCondition={true} />
        <button onClick={this.goBack} className={Classes.back_button}>
          Back
        </button>
      </div>
    );
  }
}
export default Coming;
