import React, { Component } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SideDrawer from "../../Components/SideDrawer/SideDrawer";
import Frame0 from "../../Components/Frames/Frame0/Frame0";
import Sidebar from "../../Components/Sidebar/Sidebar";

import classes from "./Homepage.module.css";

class Homepage extends Component {
  state = {
    showSideDrawer: false,
    frame: 0,
  };

  toggleSideDrawer = () => {
    this.setState((prevState) => {
      return {
        showSideDrawer: !prevState.showSideDrawer,
      };
    });
  };

  changeFrame = (frame) => {
    let prevframe = this.state.frame;
    let homepage = document.getElementById("homepage");
    if (frame === 1 || frame === 2) {
      homepage.style.setProperty("--left-adjust", "50.3915%");
      homepage.style.setProperty("--top-adjust", "27.7248%");
    } else if (frame === 3 || frame === 4) {
      homepage.style.setProperty("--left-adjust", "53.305%");
      homepage.style.setProperty("--top-adjust", "33.626%");
    } else if (frame === 5 || frame === 6) {
      homepage.style.setProperty("--left-adjust", "54.432%");
      homepage.style.setProperty("--top-adjust", "40.338%");
    }
    if (prevframe === 0 && frame !== 0) {
      homepage.classList.remove(classes.zoomout);
      homepage.classList.add(classes.zoomin);
      homepage.previousElementSibling.classList.remove(
        classes.backgroundzoomout
      );
      homepage.previousElementSibling.classList.add(classes.backgroundzoomin);
      setTimeout(() => this.setState({ frame }), 1400);
    } else if (prevframe !== 0 && frame === 0) {
      homepage.classList.remove(classes.zoomin);
      homepage.classList.remove(classes.changeframe);
      homepage.classList.add(classes.zoomout);
      homepage.previousElementSibling.classList.remove(
        classes.backgroundzoomin
      );
      homepage.previousElementSibling.classList.add(classes.backgroundzoomout);
      this.setState({ frame });
    } else if (
      (prevframe % 2 !== 0 || prevframe - 1 !== frame) &&
      (frame % 2 !== 0 || frame - 1 !== prevframe)
    ) {
      homepage.classList.add(classes.changeframe);
      setTimeout(() => this.setState({ frame }), 1000);
    } else {
      this.setState({ frame });
    }
  };

  transition = false;
  cooldown = false;

  setTransitionFalse = () => {
    this.transition = false;
  };

  moveupdown = (x) => {
    if (this.cooldown || this.transition) return;
    if (x > 0 && this.state.frame < 6) {
      this.updateparams(this.state.frame + 1);
    } else if (x < 0 && this.state.frame > 0) {
      this.updateparams(this.state.frame - 1);
    } else return;
    this.transition = true;
    this.cooldown = true;
    setTimeout(() => {
      this.cooldown = false;
    }, 500);
  };

  updateparams = (frame) => {
    let name = "";
    switch (frame) {
      case 1:
        name = "about";
        break;
      case 2:
        name = "growth";
        break;
      case 3:
        name = "theme";
        break;
      case 4:
        name = "initiatives";
        break;
      case 5:
        name = "sponsors";
        break;
      case 6:
        name = "newsletters";
        break;
    }
    this.props.history.push("/home/" + name);
  };

  scrollfootercooldown = false;

  scrollhandler = (e) => {
    if (this.state.frame !== 6 || this.scrollfootercooldown) {
      e.stopPropagation();
      e.preventDefault();
      this.scrollfootercooldown = true;
      setTimeout(() => {
        this.scrollfootercooldown = false;
      }, 500);
    } else if (document.documentElement.scrollTop > 0) return;
    if (e.deltaY > 0) this.moveupdown(1);
    else this.moveupdown(-1);
  };

  keydownhandler = (e) => {
    if (
      document.documentElement.scrollTop === 0 &&
      (e.code === "ArrowDown" || e.code === "ArrowUp")
    ) {
      if (this.state.frame !== 6) e.preventDefault();
      if (e.code === "ArrowDown") this.moveupdown(1);
      else if (e.code === "ArrowUp") this.moveupdown(-1);
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keydownhandler);
    let homepage = document.getElementById("homepage");
    homepage.addEventListener("wheel", this.scrollhandler);
    this.changeframefromparams(true);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownhandler);
  }

  componentDidUpdate() {
    if (this.state.frame !== 6) {
      document.documentElement.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    }
    this.changeframefromparams();
  }

  changeframefromparams = (immediate = false) => {
    let frame = this.props.match.params.frame;
    if (!frame) frame = 0;
    else if (frame === "about") frame = 1;
    else if (frame === "growth") frame = 2;
    else if (frame === "theme") frame = 3;
    else if (frame === "initiatives") frame = 4;
    else if (frame === "sponsors") frame = 5;
    else if (frame === "newsletters") frame = 6;
    if (this.state.frame !== frame) {
      this.changeFrame(frame);
      if (immediate) this.setState({ frame });
    }
  };

  render() {
    return (
      <>
        <Navbar
          toggleSideDrawer={this.toggleSideDrawer}
          showSideDrawer={this.state.showSideDrawer}
        />
        <SideDrawer
          show={this.state.showSideDrawer}
          toggleSideDrawer={this.toggleSideDrawer}
        />
        <Sidebar showFrame={this.updateparams} frameState={this.state.frame} />
        <Frame0
          frame={this.state.frame}
          onTransitionEnd={this.setTransitionFalse}
        />
      </>
    );
  }
}

export default Homepage;
