import React, { Component } from "react";
import "./signupform.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faGraduationCap,
  faCity,
  faHotel,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import ReactGa from "react-ga";

class signupform extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }

  render() {
    let errors = this.props.errors;

    let errordisplayName = "";
    let errordisplayEmail = "";
    let errordisplayGender = "";
    let errordisplayPhone = "";
    let errordisplayCollege = "";
    let errordisplayCollegeid = "";
    let errordisplayDepartment = "";
    let errordisplayCity = "";
    let errordisplayState = "";
    let errordisplayPassword = "";
    let errordisplayConPassword = "";
    let classesName = "formInput ";
    let classesEmail = "formInput ";
    let classesGender = "input-flex ";
    let classesPhone = "phone-input ";
    let classesCollege = "input-flex ";
    let classesCollegeid = "input-flex ";
    let classesDepartment = "formInput ";
    let classesCity = "input-flex ";
    let classesState = "input-flex ";
    let classesPassword = "input-flex ";
    let classesConPassword = "input-flex ";

    if (errors.username) {
      classesName = classesName + "red-text-field";
      errordisplayName = <div className="error-message">{errors.username}</div>;
    }
    if (errors.email) {
      classesEmail = classesEmail + "red-text-field";
      errordisplayEmail = <div className="error-message">{errors.email}</div>;
    }
    if (errors.gender) {
      classesGender = classesGender + "red-text-field";
      errordisplayGender = <div className="error-message">{errors.gender}</div>;
    }
    if (errors.phone) {
      classesPhone = classesPhone + "red-text-field";
      errordisplayPhone = <div className="error-message">{errors.phone}</div>;
    }
    if (errors.college) {
      classesCollege = classesCollege + "red-text-field";
      errordisplayCollege = (
        <div className="error-message">{errors.college}</div>
      );
    }
    if (errors.collegeid) {
      classesCollegeid = classesCollegeid + "red-text-field";
      errordisplayCollegeid = (
        <div className="error-message">{errors.collegeid}</div>
      );
    }
    if (errors.department) {
      classesDepartment = classesDepartment + "red-text-field";
      errordisplayDepartment = (
        <div className="error-message">{errors.department}</div>
      );
    }
    if (errors.city) {
      classesCity = classesCity + "red-text-field";
      errordisplayCity = <div className="error-message">{errors.city}</div>;
    }
    if (errors.state) {
      classesState = classesState + "red-text-field";
      errordisplayState = <div className="error-message">{errors.state}</div>;
    }
    if (errors.password) {
      classesPassword = classesPassword + "red-text-field";
      errordisplayPassword = (
        <div className="error-message">{errors.password}</div>
      );
    }
    if (errors.conpassword) {
      classesConPassword = classesConPassword + "red-text-field";
      errordisplayConPassword = (
        <div className="error-message">{errors.password}</div>
      );
    }

    return (
      <div className="form">
        <form
          id="form"
          onSubmit={(e) => {
            ReactGa.event({
              category: "Click",
              action: "Clicked on Sign Up in Signuppage",
            });
            this.props.function(e);
          }}
          className="forminner"
        >
          <div className={classesName}>
            <FontAwesomeIcon
              icon={faUser}
              className={`formLogo ${errors.username ? "red-icon" : ""}`}
            />
            <input id="name" type="text" placeholder="Full Name"></input>
          </div>
          {errordisplayName}

          <div className={classesEmail}>
            <FontAwesomeIcon
              icon={faEnvelope}
              className={`formLogo ${errors.email ? "red-icon" : ""}`}
            />
            <input id="email" type="email" placeholder="Email Address"></input>
          </div>
          {errordisplayEmail}
          <div className="sid">
            <div>
              <FontAwesomeIcon
                icon={faPeopleArrows}
                className={`formLogo ${errors.gender ? "red-icon" : ""}`}
              />
              <input
                id="gender"
                type="text"
                placeholder={"Gender"}
                className={classesGender}
              ></input>
              {errordisplayGender}
            </div>

            <div className="sidz">
              <FontAwesomeIcon
                icon={faPhoneAlt}
                className={`formLogo ${errors.phone ? "red-icon" : ""}`}
              />
              <input
                id="phone"
                type="tel"
                placeholder="Phone Number"
                className={classesPhone}
              ></input>
              {errordisplayPhone}
            </div>
          </div>
          <div className="sid2">
            <div>
              <FontAwesomeIcon
                icon={faGraduationCap}
                className={`formLogo2 ${errors.college ? "red-icon" : ""}`}
              />
              <input
                className={classesCollege}
                id="college"
                type="text"
                placeholder={"College"}
              ></input>
              {errordisplayCollege}
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPen}
                className={`formLogo2 ${errors.collegeid ? "red-icon" : ""}`}
              />
              <input
                className="input-flex"
                id="clgid"
                type="text"
                placeholder="College ID"
                className={classesCollegeid}
              ></input>
              {errordisplayCollegeid}
            </div>
          </div>
          <div className={classesDepartment}>
            <FontAwesomeIcon
              icon={faBook}
              className={`formLogo ${errors.department ? "red-icon" : ""}`}
            />
            <input id="dep" type="text" placeholder="Department"></input>
          </div>
          {errordisplayDepartment}
          <div className="sid2">
            <div>
              <FontAwesomeIcon
                icon={faCity}
                className={`formLogo2 ${errors.city ? "red-icon" : ""}`}
              />
              <input
                className="input-flex"
                id="city"
                type="text"
                placeholder="City"
                className={classesCity}
              ></input>
              {errordisplayCity}
            </div>
            <div>
              <FontAwesomeIcon
                icon={faHotel}
                className={`formLogo2 ${errors.state ? "red-icon" : ""}`}
              />
              <input
                className="input-flex"
                id="state"
                type="text"
                placeholder="State"
                className={classesState}
              ></input>
              {errordisplayState}
            </div>
          </div>
          <div className="sid2">
            <div>
              <FontAwesomeIcon
                icon={faLock}
                className={`formLogo2 ${errors.password ? "red-icon" : ""}`}
              />
              <input
                className={classesPassword}
                id="password"
                type="Password"
                placeholder="Password"
              ></input>
              {errordisplayPassword}
            </div>

            <div>
              <FontAwesomeIcon
                icon={faLock}
                className={`formLogo2 ${errors.conpassword ? "red-icon" : ""}`}
              />
              <input
                className={classesConPassword}
                id="conpassword"
                type="Password"
                placeholder="Confirm Password"
              ></input>
              {errordisplayConPassword}
            </div>
          </div>
          <button type="submit" className="formSubmit">Sign Up</button>
        </form>
      </div>
    );
  }
}

export default signupform;
