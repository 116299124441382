import classes from "./Footer.module.css";
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga";

import footerilu from "../../../../images/footer-ilu.png";

import { regForNewsletters } from "../../../../actions/authActions";

const closeFooterAndGAclick = (e) => {
  ReactGA.event({
    category: "Click",
    action: "Clicked " + e.target.innerText + " in Footer",
  });
  window.scroll({ left: 0, top: 0, behavior: "smooth" });
};

const Footer = () => (
  <div className={classes.Footer} id="footer">
    <Link onClick={closeFooterAndGAclick} to="/">
      <h1 className={classes.title}>
        <img src={footerilu} alt="Kshitij 2021" style={{ width: "120%" }} />
      </h1>
    </Link>
    <div className={classes.middlediv}>
      <div className={classes.Homepagelinks}>
        <div>
          <HashLink onClick={closeFooterAndGAclick} to="/home/#about">
            About Us
          </HashLink>
        </div>
        <div>
          <HashLink onClick={closeFooterAndGAclick} to="/home/#growth">
            Growth
          </HashLink>
        </div>
        <div>
          <HashLink onClick={closeFooterAndGAclick} to="/home/#theme">
            Theme
          </HashLink>
        </div>
        <div>
          <HashLink onClick={closeFooterAndGAclick} to="/home/#initiatives">
            Initiatives
          </HashLink>
        </div>
        <div>
          <HashLink onClick={closeFooterAndGAclick} to="/home/#sponsors">
            Sponsors
          </HashLink>
        </div>
      </div>
      <div className={classes.Otherlinks}>
        <div className={classes.Activitieslinks}>
          <Link onClick={closeFooterAndGAclick} to="/events">
            Events
          </Link>
          <ul className={classes.Activitiessublinks}>
            <li>
              <Link onClick={closeFooterAndGAclick} to="/events/summit">
                Summits
              </Link>
            </li>
            <li>
              <Link
                onClick={closeFooterAndGAclick}
                to="/events/interactiveSession"
              >
                Interactive Sessions
              </Link>
            </li>
            <li>
              <Link onClick={closeFooterAndGAclick} to="/events/competitions">
                Competitions
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.Activitieslinks}>
          <Link onClick={closeFooterAndGAclick} to="/activities">
            Activities
          </Link>
          <ul className={classes.Activitiessublinks}>
            <li>
              <Link
                onClick={closeFooterAndGAclick}
                to="/activities/exhibitions"
              >
                Exhibitions
              </Link>
            </li>
            <li>
              <Link
                onClick={closeFooterAndGAclick}
                to="/activities/guestlectures"
              >
                Guest Lectures
              </Link>
            </li>
            <li>
              <Link onClick={closeFooterAndGAclick} to="/activities/megashows">
                Megashows
              </Link>
            </li>
            <li>
              <Link onClick={closeFooterAndGAclick} to="/activities/workshops">
                Workshops
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.Activitieslinks}>
          <Link onClick={closeFooterAndGAclick} to="/contactus">
            Contact Us
          </Link>
          <ul className={classes.Activitiessublinks}>
            <li>
              <Link onClick={closeFooterAndGAclick} to="/contactus/core">
                Core Team
              </Link>
            </li>
            <li>
              <Link onClick={closeFooterAndGAclick} to="/contactus/design">
                Design Team
              </Link>
            </li>
            <li>
              <Link onClick={closeFooterAndGAclick} to="/contactus/web">
                Web Team
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className={classes.thirddiv}>
      <div className={classes.letsconnect}>
        <span>Let's Connect</span>
        <div className={classes.connectlinks}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/ktj.iitkgp"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked Facebook Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/ktj.iitkgp"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked Instagram Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/kshitij-iit-kharagpur"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked LinkedIn Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://youtube.com/user/ktjiitkgp"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked Youtube Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
      </div>
      <div className={classes.register}>
        <label htmlFor="email" style={{ fontSize: "80%" }}>
          Register for Newsletters:
        </label>
        <input
          type="email"
          name="email"
          placeholder="Email"
          id="footer-email"
        />
        <input
          type="submit"
          style={{ flex: ".2", color: "inherit" }}
          onClick={() => {
            ReactGA.event({
              category: "Click",
              action: "Clicked Submit in Footer",
            });
            alert("Registerations not active now");
          }}
        />
      </div>
    </div>
  </div>
);

export default Footer;
