import React, { Component } from "react";
import "./signinform.css";
import "../vdoplayer/Vdo";
import "../../images/bgvdo.mp4";
import { Link } from "react-router-dom";
import ReactGa from "react-ga";

class SignInform extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }
  render() {
    let errors = this.props.errors;

    let errordisplayEmail = "";
    let errordisplayPassword = "";

    let classesEmail = "formInput ";
    let classesPassword = "formInput ";

    if (errors.email) {
      classesEmail = classesEmail + "red-text-field";
      errordisplayEmail = <div className="error-message">{errors.email}</div>;
    }
    if (errors.password) {
      classesPassword = classesPassword + "red-text-field";
      errordisplayPassword = (
        <div className="error-message">{errors.password}</div>
      );
    }

    return (
      <div className="outer">
        <div className="signOuter">
          <form
            onSubmit={(e) => {
              ReactGa.event({
                category: "Click",
                action: "Clicked on Sign In in Signinpage",
              });
              this.props.function(e);
            }}
            className="formOuter"
          >
            <input
              type="text"
              className={classesEmail}
              placeholder="Email-ID"
              id="email"
            ></input>
            {errordisplayEmail}
            <input
              type="password"
              className={classesPassword}
              placeholder="Enter Password"
              id="password"
            ></input>
            {errordisplayPassword}
            <input type="submit" className="formSubmit" value="Sign In" />
            <Link
              onClick={() => {
                ReactGa.event({
                  category: "Click",
                  action: "Clicked on Forget Password in Signinpage",
                });

                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/resetpassword"
            >
              <button className="formSubmit">Forgot Password</button>
            </Link>
          </form>
        </div>
      </div>
    );
  }
}

export default SignInform;
