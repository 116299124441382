import React from "react";
import Countdown from "react-countdown";
import classes from "./Countdown.module.css";
import ilu from "./ilu.png";

const renderer = ({ days, minutes, hours }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 389.02 151.92"
    style={{ width: "75%", margin: ".5em auto", display: "block" }}
  >
    <defs>
      <linearGradient
        id="countdown-linear-gradient"
        x1="59.58"
        y1="26.66"
        x2="107.45"
        y2="26.66"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4b27a9" />
        <stop offset="0.53" stopColor="#6444b5" />
        <stop offset="1" stopColor="#7c61c0" />
      </linearGradient>
      <linearGradient
        id="countdown-linear-gradient-2"
        x1="59.42"
        y1="83.14"
        x2="107.29"
        y2="83.14"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-3"
        x1="2.4"
        y1="26.77"
        x2="50.27"
        y2="26.77"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-4"
        x1="2.24"
        y1="83.24"
        x2="50.11"
        y2="83.24"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-5"
        x1="199.1"
        y1="26.66"
        x2="246.97"
        y2="26.66"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-6"
        x1="198.94"
        y1="83.14"
        x2="246.81"
        y2="83.14"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-7"
        x1="141.92"
        y1="26.77"
        x2="189.79"
        y2="26.77"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-8"
        x1="141.76"
        y1="83.24"
        x2="189.63"
        y2="83.24"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-9"
        x1="338.62"
        y1="26.66"
        x2="386.49"
        y2="26.66"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-10"
        x1="338.46"
        y1="83.14"
        x2="386.33"
        y2="83.14"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-11"
        x1="281.44"
        y1="26.77"
        x2="329.31"
        y2="26.77"
        xlinkHref="#countdown-linear-gradient"
      />
      <linearGradient
        id="countdown-linear-gradient-12"
        x1="281.28"
        y1="83.24"
        x2="329.15"
        y2="83.24"
        xlinkHref="#countdown-linear-gradient"
      />
    </defs>
    <g data-name="Layer 2">
      <g id="countdown">
        <path
          className={classes.cls1}
          d="M59.58,2.94l.28,47.81a2.37,2.37,0,0,0,2.37,2.35h42.85a2.37,2.37,0,0,0,2.37-2.37V2.59A2.36,2.36,0,0,0,105.06.23L61.93.56A2.36,2.36,0,0,0,59.58,2.94Z"
        />
        <path
          className={classes.cls2}
          d="M59.42,59.41l.29,47.81a2.36,2.36,0,0,0,2.36,2.35h42.85a2.37,2.37,0,0,0,2.37-2.36V59.07a2.37,2.37,0,0,0-2.39-2.37L61.77,57A2.36,2.36,0,0,0,59.42,59.41Z"
        />
        <path
          className={classes.cls3}
          d="M59.59,48.41h0A2.37,2.37,0,0,1,62,50.77V59a2.37,2.37,0,0,1-2.37,2.36h0A2.36,2.36,0,0,1,57.18,59l0-8.25A2.36,2.36,0,0,1,59.59,48.41Z"
        />
        <path
          className={classes.cls3}
          d="M107.61,48h0A2.36,2.36,0,0,1,110,50.36v8.26A2.37,2.37,0,0,1,107.61,61h0a2.37,2.37,0,0,1-2.37-2.39l0-8.25A2.36,2.36,0,0,1,107.61,48Z"
        />
        <text className={classes.cls4} transform="translate(83.67 89.57)">
          {days % 10}
        </text>
        <rect
          className={classes.cls5}
          x="61.99"
          y="53.11"
          width="43.18"
          height="3.98"
        />
        <path
          className={classes.cls6}
          d="M2.4,3l.28,47.81a2.37,2.37,0,0,0,2.37,2.36H47.9a2.37,2.37,0,0,0,2.37-2.37V2.7A2.37,2.37,0,0,0,47.88.33L4.75.66A2.37,2.37,0,0,0,2.4,3Z"
        />
        <path
          className={classes.cls7}
          d="M2.24,59.52l.29,47.81a2.36,2.36,0,0,0,2.36,2.35H47.75a2.37,2.37,0,0,0,2.36-2.37V59.17a2.36,2.36,0,0,0-2.38-2.36l-43.14.33A2.37,2.37,0,0,0,2.24,59.52Z"
        />
        <path
          className={classes.cls3}
          d="M2.42,48.51h0a2.37,2.37,0,0,1,2.36,2.37v8.25A2.37,2.37,0,0,1,2.42,61.5h0A2.36,2.36,0,0,1,0,59.12l.05-8.25A2.37,2.37,0,0,1,2.42,48.51Z"
        />
        <path
          className={classes.cls3}
          d="M50.43,48.1h0a2.37,2.37,0,0,1,2.37,2.37v8.25a2.37,2.37,0,0,1-2.37,2.37h0A2.37,2.37,0,0,1,48,58.71l.05-8.25A2.37,2.37,0,0,1,50.43,48.1Z"
        />
        <text className={classes.cls4} transform="translate(26.5 89.68)">
          {parseInt((days / 10) % 10)}
        </text>
        <rect
          className={classes.cls5}
          x="4.82"
          y="53.22"
          width="43.18"
          height="3.98"
        />
        <text className={classes.cls8} transform="translate(31.24 137.98)">
          DAYS
        </text>
        <text className={classes.cls8} transform="translate(165.89 138.53)">
          HOURS
        </text>
        <path
          className={classes.cls9}
          d="M199.1,2.94l.28,47.81a2.37,2.37,0,0,0,2.37,2.35H244.6A2.37,2.37,0,0,0,247,50.73V2.59A2.36,2.36,0,0,0,244.58.23L201.45.56A2.36,2.36,0,0,0,199.1,2.94Z"
        />
        <path
          className={classes.cls10}
          d="M198.94,59.41l.29,47.81a2.36,2.36,0,0,0,2.36,2.35h42.85a2.37,2.37,0,0,0,2.37-2.36V59.07a2.36,2.36,0,0,0-2.38-2.37L201.29,57A2.36,2.36,0,0,0,198.94,59.41Z"
        />
        <path
          className={classes.cls3}
          d="M199.12,48.41h0a2.36,2.36,0,0,1,2.36,2.36V59a2.36,2.36,0,0,1-2.36,2.36h-.05A2.36,2.36,0,0,1,196.7,59l.05-8.25A2.36,2.36,0,0,1,199.12,48.41Z"
        />
        <path
          className={classes.cls3}
          d="M247.13,48h0a2.36,2.36,0,0,1,2.36,2.36v8.26A2.37,2.37,0,0,1,247.13,61h0a2.37,2.37,0,0,1-2.37-2.39l0-8.25A2.37,2.37,0,0,1,247.13,48Z"
        />
        <text className={classes.cls4} transform="translate(223.2 89.57)">
          {hours % 10}
        </text>
        <rect
          className={classes.cls5}
          x="201.51"
          y="53.11"
          width="43.18"
          height="3.98"
        />
        <path
          className={classes.cls11}
          d="M141.92,3l.28,47.81a2.37,2.37,0,0,0,2.37,2.36h42.85a2.37,2.37,0,0,0,2.37-2.37V2.7A2.37,2.37,0,0,0,187.4.33L144.27.66A2.37,2.37,0,0,0,141.92,3Z"
        />
        <path
          className={classes.cls12}
          d="M141.76,59.52l.29,47.81a2.36,2.36,0,0,0,2.37,2.35h42.85a2.37,2.37,0,0,0,2.36-2.37V59.17a2.36,2.36,0,0,0-2.38-2.36l-43.14.33A2.37,2.37,0,0,0,141.76,59.52Z"
        />
        <path
          className={classes.cls3}
          d="M141.94,48.51h0a2.37,2.37,0,0,1,2.37,2.37v8.25a2.37,2.37,0,0,1-2.37,2.37h-.05a2.37,2.37,0,0,1-2.37-2.38l0-8.25A2.37,2.37,0,0,1,141.94,48.51Z"
        />
        <path
          className={classes.cls3}
          d="M190,48.1h0a2.37,2.37,0,0,1,2.37,2.37v8.25A2.37,2.37,0,0,1,190,61.09h0a2.37,2.37,0,0,1-2.37-2.38l.05-8.25A2.37,2.37,0,0,1,190,48.1Z"
        />
        <text className={classes.cls4} transform="translate(166.02 89.68)">
          {parseInt((hours / 10) % 10)}
        </text>
        <rect
          className={classes.cls5}
          x="144.34"
          y="53.22"
          width="43.18"
          height="3.98"
        />
        <text className={classes.cls8} transform="translate(294.99 137.77)">
          MINUTES
        </text>
        <path
          className={classes.cls13}
          d="M338.62,2.94l.28,47.81a2.37,2.37,0,0,0,2.37,2.35h42.85a2.37,2.37,0,0,0,2.37-2.37V2.59A2.36,2.36,0,0,0,384.1.23L341,.56A2.36,2.36,0,0,0,338.62,2.94Z"
        />
        <path
          className={classes.cls14}
          d="M338.46,59.41l.29,47.81a2.36,2.36,0,0,0,2.36,2.35H384a2.37,2.37,0,0,0,2.37-2.36V59.07A2.36,2.36,0,0,0,384,56.7L340.81,57A2.37,2.37,0,0,0,338.46,59.41Z"
        />
        <path
          className={classes.cls3}
          d="M338.64,48.41h0A2.36,2.36,0,0,1,341,50.77V59a2.36,2.36,0,0,1-2.36,2.36h-.05A2.36,2.36,0,0,1,336.22,59l0-8.25A2.36,2.36,0,0,1,338.64,48.41Z"
        />
        <path
          className={classes.cls3}
          d="M386.65,48h0A2.37,2.37,0,0,1,389,50.36v8.26A2.37,2.37,0,0,1,386.65,61h0a2.37,2.37,0,0,1-2.37-2.39l0-8.25A2.37,2.37,0,0,1,386.65,48Z"
        />
        <text className={classes.cls4} transform="translate(362.72 89.57)">
          {minutes % 10}
        </text>
        <rect
          className={classes.cls5}
          x="341.04"
          y="53.11"
          width="43.18"
          height="3.98"
        />
        <path
          className={classes.cls15}
          d="M281.44,3l.29,47.81a2.36,2.36,0,0,0,2.36,2.36h42.85a2.37,2.37,0,0,0,2.37-2.37V2.7A2.37,2.37,0,0,0,326.93.33L283.79.66A2.37,2.37,0,0,0,281.44,3Z"
        />
        <path
          className={classes.cls16}
          d="M281.28,59.52l.29,47.81a2.36,2.36,0,0,0,2.37,2.35h42.85a2.37,2.37,0,0,0,2.36-2.37V59.17a2.36,2.36,0,0,0-2.38-2.36l-43.14.33A2.37,2.37,0,0,0,281.28,59.52Z"
        />
        <path
          className={classes.cls3}
          d="M281.46,48.51h0a2.37,2.37,0,0,1,2.37,2.37v8.25a2.37,2.37,0,0,1-2.37,2.37h0A2.37,2.37,0,0,1,279,59.12l0-8.25A2.37,2.37,0,0,1,281.46,48.51Z"
        />
        <path
          className={classes.cls3}
          d="M329.47,48.1h0a2.37,2.37,0,0,1,2.37,2.37v8.25a2.37,2.37,0,0,1-2.37,2.37h-.05a2.38,2.38,0,0,1-2.37-2.38l.05-8.25A2.37,2.37,0,0,1,329.47,48.1Z"
        />
        <text className={classes.cls4} transform="translate(305.54 89.68)">
          {parseInt((minutes / 10) % 10)}
        </text>
        <rect
          className={classes.cls5}
          x="283.86"
          y="53.22"
          width="43.18"
          height="3.98"
        />
      </g>
    </g>
  </svg>
);

const countdown = () => (
  <div
    style={{
      width: "22%",
      top: "59%",
      position: "relative",
      margin: "0 auto",
      // backgroundColor: "rgba(0,0,0,.2)",
      paddingTop: "2rem",
    }}
  >
    <img src={ilu} alt="" style={{ width: "100%" }} />
    <Countdown date={Date.parse("January 29, 2021")} renderer={renderer} />
  </div>
);

export default countdown;
