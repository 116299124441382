import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./Table.module.css";

class Table extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  render() {
    const sorted = [...this.props.auth.user.events].sort();
    return (
      <div className={classes.table}>
        <table>
          <tbody>
            <tr>
              <td className={classes.white}>NAME</td>
              <td className={classes.dcolor}>
                {this.props.auth.user.username}
              </td>
            </tr>
            <tr>
              <td className={classes.white}>KTJ ID</td>
              <td className={classes.dcolor}>{this.props.auth.user.ktjID}</td>
            </tr>
            <tr>
              <td className={classes.white}>EMAIL ID</td>
              <td className={classes.dcolor}>{this.props.auth.user.email}</td>
            </tr>
            <tr>
              <td className={classes.white}>CONTACT NUMBER</td>
              <td className={classes.dcolor}>{this.props.auth.user.phone}</td>
            </tr>
            <tr>
              <td className={classes.white}>GENDER</td>
              <td className={classes.dcolor}>{this.props.auth.user.gender}</td>
            </tr>
            <tr>
              <td className={classes.white}>COLLEGE ID</td>
              <td className={classes.dcolor}>
                {this.props.auth.user.collegeid}
              </td>
            </tr>
            <tr>
              <td className={classes.white}>DEPARTMENT</td>
              <td className={classes.dcolor}>
                {this.props.auth.user.department}
              </td>
            </tr>
            <tr>
              <td className={classes.white}>CITY STATE</td>
              <td className={classes.dcolor}>
                {this.props.auth.user.city}, {this.props.auth.user.state}
              </td>
            </tr>

            <tr>
              <td className={classes.registered}>REGISTERED EVENTS</td>
              <td className={classes.scrollbar}>
                <table className={classes.eventTable}>
                  <tbody>
                    {sorted.map((value, index) => (
                      <tr>
                        <td
                          className={
                            index % 2 ? classes.color1 : classes.color2
                          }
                        >
                          {value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        {/*<button className={classes.buttonStyle}>EDIT DETAILS</button>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Table));
