import React, { Component } from "react";
import "./ForgotPassword.css";
import ReactGa from "react-ga";

import { forgetPassword } from "../../../actions/authActions";
import SideDrawer from "../Components/SideDrawer/SideDrawer";

class ForgotPassword extends Component {
  submitform = () => {
    ReactGa.event({
      category: "Click",
      action: "Clicked on Forgot Password in forgotpasswordpage",
    });
    let email = document.getElementById("email").value.trim();
    forgetPassword({ email });
  };
  render() {
    return (
      <div style={{ backgroundColor: "#1e1762" }}>
        <SideDrawer />
        <form className="forgetOuter">
          <input
            type="email"
            className="forgetEmail"
            placeholder="Email-Id"
            id="email"
          ></input>
          <input
            type="submit"
            value="Forgot Password"
            className="forgetFormSubmit"
            onClick={this.submitform}
          ></input>
        </form>
      </div>
    );
  }
}

export default ForgotPassword;
