import React, { useState } from "react";
import "./Homepage.css";
import Room from "./Components/Room/Room";
import SideDrawer from "./Components/SideDrawer/SideDrawer";
import { Link } from "react-router-dom";
import { regForNewsletters } from "../../actions/authActions";
import ReactGa from "react-ga";

import Frame1_video1 from "../../Videos/Frame1_video1.webm";
import Frame1_video2 from "../../Videos/Frame1_video2.webm";
import Frame2_video1 from "../../Videos/Frame2_video1.webm";
import Frame2_video2 from "../../Videos/Frame2_video2.webm";
import Frame3_video1 from "../../Videos/Frame3_video1.webm";
import Frame3_video2 from "../../Videos/Frame3_video2.webm";
import Frame4_video1 from "../../Videos/Frame4_video1.webm";
import Frame4_video2 from "../../Videos/Frame4_video2.webm";
import Frame5_video1 from "../../Videos/Frame5_video1.webm";
import Frame5_video2 from "../../Videos/Frame5_video2.webm";
import Frame6_video1 from "../../Videos/Frame6_video1.webm";
import Frame6_video2 from "../../Videos/Frame6_video2.webm";

const Homepage = (props) => {
  const [expanded, expand] = useState(0);

  const submitform = (e) => {
    e.preventDefault();
    ReactGa.event({
      category: "Click",
      action: "Clicked on Submit in Newsletters Homepage Mobile",
    });
    alert('Registerations not active now');
    // let email = document.getElementById("newsletter-email").value;
    // regForNewsletters(email);
  };
  return (
    <div className="homepagemobileApp">
      <SideDrawer />
      <div
        style={{ position: "relative", zIndex: 1, backgroundColor: "white" }}
      >
        <img src="./images/bg.png" alt="background"></img>
      </div>
      <Room
        bg="./images/bg1.png"
        frameNumber="1"
        name="About Us"
        expanded={expanded === 1}
        click={() => expand(expanded === 1 ? 0 : 1)}
        gif={Frame1_video1}
        circularvid={Frame1_video2}
        id="about"
      >
        <p>
          Kshitij, the annual techno-management symposium of IIT Kharagpur, has
          been going from strength to strength, celebrating the spirit of
          science and technology, congregating students all over India to
          showcase their scientific and managerial dexterity.
        </p>
        <p>
          We are known to provide an assorted mix of a galaxy of events with the
          synchronized conduction of workshops, technical exhibitions, and guest
          lectures by people of the utmost stature in the fields of technology
          and entrepreneurship. Events spread across from technology to
          management provides students with the opportunity to gauge their
          strengths and aim higher than ever before. From applying their
          technical know-how to presenting their ideas in front of potential
          investors, Kshitji has been challenging students to come up with
          something unique, which they have successfully done year after year.
        </p>
      </Room>
      <Room
        bg="./images/bg2.png"
        frameNumber="2"
        name="Growth"
        expanded={expanded === 2}
        click={() => expand(expanded === 2 ? 0 : 2)}
        gif={Frame2_video1}
        circularvid={Frame2_video2}
        id="growth"
      >
        <p>
          Since its inception in 2004, Kshitij has grown to be Asia's largest
          Techno-management festival. Year after year, Kshitij has empowered the
          technically inclined youth with the opportunities to showcase their
          talents, get recognized & rewarded, and get unique learning
          experiences. With the ever-increasing reach and participation from
          various technical institutes and educational communities across the
          world, Kshtitij offers exposure to cutting edge technologies and
          skills that inspire the youth to build world-changing solutions using
          technology.
        </p>
      </Room>
      <Room
        bg="./images/bg3.png"
        frameNumber="3"
        name="Theme"
        expanded={expanded === 3}
        click={() => expand(expanded === 3 ? 0 : 3)}
        gif={Frame3_video1}
        circularvid={Frame3_video2}
        id="theme"
      >
        <p>
          Future is definitely something to behold. Our actions continuously
          mold and redefine our future, navigating us through the infinite
          possibilities.
        </p>
        <p>
          Over the ages, people with hope and belief have looked onto the sun on
          the horizon, pondering over the transient nature of this world. Always
          wishing for a way to get a glimpse of what awaits them. This is one of
          the very few constants that remain as we transition from one timeline
          to another.
        </p>
        <p>
          Kshitij, IIT Kharagpur is proud to present the theme of its 18th
          edition, <strong>Ticking Timelines.</strong>
        </p>
      </Room>
      <Room
        bg="./images/bg4.png"
        frameNumber="4"
        name="Initiatives"
        expanded={expanded === 4}
        click={() => expand(expanded === 4 ? 0 : 4)}
        gif={Frame4_video1}
        circularvid={Frame4_video2}
        id="initiatives"
      >
        <p>
          Apart from the events, workshops, exhibitions and the other host of
          activities that takes place in Kshitij, we also take up social
          initiatives every year in an endeavor to make a difference in society.
          Giving back can provide your life with more meaning and make you grow
          in unexpected ways. It is a way of being a better person and living
          your values every day.
        </p>
        <p>Some of the initiatives taken up in the previous editions were :</p>
        <ol>
          <li>
            Astitva, an initiative to bring about awareness about mental health
            care.{" "}
          </li>
          <li>
            Prakriti, an initiative to optimize solid waste management using
            Artificial Intelligence and concepts of Robotics.
          </li>
          <li>
            Sankalp, an initiative taken up in association with UNICEF with an
            aim to work towards eradicating child malnutrition.
          </li>
          <li>
            Digital India Summit, held under the aegis of Prime Minister
            Narendra Modi's vision to make all parts of the country digitally
            empowered in the field of technology.
          </li>
          <li>
            An initiative was taken up in association with PETA to voice the
            need for the ethical treatment of animals.
          </li>
          <li>
            Ankur, in association with Kailash Satyarthi Children Foundation,
            conducted a campaign for educating children in rural areas.
          </li>
          <li>
            Illuminate, in association with the NGO Drishti, working towards the
            cause of eye donation.
          </li>
        </ol>
        <p>
          No matter who you are, being an altruist, and helping others will
          enhance your life and create new opportunities for connection,
          self-efficacy, and confidence.
        </p>
        <p>
          For the upcoming edition, we plan to conduct a social initiative
          keeping in mind our commitments and vision for a better world. We
          believe that changes at a small level can have a huge impact and that
          is our responsibility to make this happen as makers of social change.
        </p>
      </Room>
      <Room
        bg="./images/bg5.png"
        frameNumber="5"
        name="Sponsors"
        expanded={expanded === 5}
        click={() => expand(expanded === 5 ? 0 : 5)}
        gif={Frame5_video1}
        circularvid={Frame5_video2}
        id="sponsors"
      >
        <p>
          Our sponsors are an integral part of our fest culture, and we look
          forward to them as our partners in innovative education. We are
          incredibly thankful to each sponsor and partner, without whom the
          symposium would not be possible. For more details on our sponsors,
          kindly check out the sponsors' page.
        </p>
        <Link
          onClick={() => {
            ReactGa.event({
              category: "Click",
              action: "Clicked on Sponsors in Homepage Mobile",
            });
            window.scroll({ left: 0, top: 0, behavior: "smooth" });
          }}
          to="/sponsors"
        >
          Click to know more&rarr;
        </Link>
      </Room>
      <Room
        bg="./images/bg6.png"
        frameNumber="6"
        name="Register for Newsletters"
        expanded={expanded === 6}
        click={() => expand(expanded === 6 ? 0 : 6)}
        gif={Frame6_video1}
        circularvid={Frame6_video2}
        id="newsletters"
      >
        <form id="form-newsletters">
          <h3 style={{ marginBottom: "1rem" }}>
            Enter your Email-Id to subscribe to our newsletters:
          </h3>
          <input
            type="email"
            id="newsletter-email"
            placeholder="Email"
            style={{ width: "65%" }}
          />
          <input
            type="submit"
            style={{ width: "25%" }}
            onClick={submitform}
          ></input>
        </form>
      </Room>
    </div>
  );
};

export default Homepage;
