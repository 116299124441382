// Packages import
import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CustomPreloader } from "react-preloaders";
import Loadable from "react-loadable";
import ReactGa from "react-ga";

// Loader Import
import Loader from "./Components/Loader";

// Desktop Route Imports
import Coming from "./Components/Coming_soon/Coming";
import Footer from "./Components/Footer/Footer";
import ForgotPassword from "./Components/Form/forgotpassword";
import Homepage from "./Containers/Homepage/Homepage";
import Profile from "./Components/Profile/Profile";
import SignIn from "./pages/signIn/signIn";
import Signup from "./pages/signup/signup";
import SignupMess from "./pages/signupMess/signupMess";
import Schedule from "./pages/schedule/Schedule2";

// Mobile Route Imports
import MobileComing from "./mobile/src/Components/Coming_Soon/Coming";
import MobileFooter from "./mobile/src/Components/Footer/Footer";
import MobileForgotPassword from "./mobile/src/Form/ForgotPassword";
import MobileHomepage from "./mobile/src/Homepage";
import MobileProfile from "./mobile/src/Components/Profile/Profile";
import MobileSignIn from "./mobile/src/Form/signinform";
import MobileSignUp from "./mobile/src/Form/signupform";
import MobileSignupMess from "./mobile/src/Components/SignupMess/signupmess";

// Function Imports
import API from "./api";
import { setCurrentUser } from "./actions/authActions";
import store from "./store";

// Media Imports
import preload_gif from "./images/preloader.webm";
import preloader_mobile from "./images/preloader_mobile.webm";

const useEffect = () => {
  const getCsrfToken = async () => {
    ReactGa.initialize("UA-186285820-1");
    const { data } = await API.get("/csrf-token");
    API.defaults.headers.post["X-CSRF-Token"] = data.csrfToken;
  };
  getCsrfToken();
};

const breakingpoint = 996;

// Loading Screen for CodeSplit
const Loading = () => (
  <div style={{ width: "100%", height: "100vh", backgroundColor: "#251F5A" }}>
    <Loader />
  </div>
);

// Desktop CodeSplit Starts
const AsyncEvents = Loadable({
  loader: () => import("./Components/Events/event_main"),
  loading: Loading,
});
const AsyncSponsors = Loadable({
  loader: () => import("./pages/Sponsors/Sponsors"),
  loading: Loading,
});
const AsyncActivities = Loadable({
  loader: () => import("./pages/activities/Activities_routes"),
  loading: Loading,
});
const AsyncContactUs = Loadable({
  loader: () => import("./Components/Contactus/Contactus_main"),
  loading: Loading,
});

// Mobile CodeSplit Starts
const AsyncMobileEvents = Loadable({
  loader: () => import("./mobile/src/Components/Events/event_main"),
  loading: Loading,
});
const AsyncMobileSponsors = Loadable({
  loader: () => import("./pages/Sponsors/Sponsors"),
  loading: Loading,
});
const AsyncMobileActivities = Loadable({
  loader: () => import("./mobile/src/pages/activities/Activities_routes"),
  loading: Loading,
});
const AsyncMobileContactUs = Loadable({
  loader: () => import("./mobile/src/Components/Contactus/Contactus_main"),
  loading: Loading,
});

class App extends Component {
  state = {
    windowwidth: window.innerWidth,
  };
  componentDidUpdate() {
    ReactGa.pageview(this.props.location.pathname);
    useEffect();
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    ReactGa.pageview(this.props.location.pathname);
    useEffect();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    if (
      (window.innerWidth - breakingpoint) *
        (this.state.windowwidth - breakingpoint) <
      0
    )
      this.setState({ windowwidth: window.innerWidth });
  };
  render() {
    this.useEffect;
    if (localStorage.ktjUserLoginData) {
      store.dispatch(setCurrentUser(JSON.parse(localStorage.ktjUserLoginData)));
    }
    if (this.state.windowwidth > breakingpoint)
      return (
        <React.Fragment>
          <Provider store={store}>
            <div className="App" id="App">
              <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/schedule" component={Schedule} />
                <Route path="/activities" component={AsyncActivities} />
                <Route exact path="/coming" component={Coming} />
                <Route path="/contactus" component={AsyncContactUs} />
                <Route path="/events" component={AsyncEvents} />
                <Route exact path="/home/" component={Homepage} />
                <Route path="/home/:frame" component={Homepage} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/resetpassword" component={ForgotPassword} />
                <Route exact path="/signin" component={SignIn} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/signupmessage" component={SignupMess} />
                <Route exact path="/sponsors" component={AsyncSponsors} />
              </Switch>
            </div>
            <Footer />
          </Provider>
          <CustomPreloader background="#000000">
            <video
              alt="loading..."
              style={{ width: "100%" }}
              loop
              autoPlay
              muted
              controls={false}
            >
              <source src={preload_gif} type="video/webm" />
            </video>
          </CustomPreloader>
        </React.Fragment>
      );
    else
      return (
        <React.Fragment>
          <Provider store={store}>
            <Switch>
              <Route exact path="/" component={MobileHomepage} />
              <Route exact path="/schedule" component={Schedule} />
              <Route path="/activities" component={AsyncMobileActivities} />
              <Route exact path="/coming" component={MobileComing} />
              <Route path="/Contactus" component={AsyncMobileContactUs} />
              <Route path="/events" component={AsyncMobileEvents} />
              <Route path="/home" component={MobileHomepage} />
              <Route exact path="/profile" component={MobileProfile} />
              <Route exact path="/signin" component={MobileSignIn} />
              <Route exact path="/signup" component={MobileSignUp} />
              <Route exact path="/signupmessage" component={MobileSignupMess} />
              <Route exact path="/sponsors" component={AsyncMobileSponsors} />
              <Route
                exact
                path="/resetpassword"
                component={MobileForgotPassword}
              />
            </Switch>
            <MobileFooter />
          </Provider>
          <CustomPreloader background="#000000">
            <video
              alt="loading..."
              style={{ width: "100%" }}
              loop
              autoPlay
              muted
              controls={false}
            >
              <source src={preloader_mobile} type="video/webm" />
            </video>
          </CustomPreloader>
        </React.Fragment>
      );
  }
}

export default withRouter(App);
