import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGa from "react-ga";

import "./signupform.css";
import SideDrawer from "../Components/SideDrawer/SideDrawer.js";
import { registerUser } from "../../../actions/authActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faGraduationCap,
  faCity,
  faHotel,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";

class Form extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
      redirectToProfile: this.redirectToProfile.bind(this),
    };
  }

  redirectToProfile = () => {
    this.props.history.push("/profile");
  };

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        model: prevState.redirectToProfile(nextProps.model),
      };
    }
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }

  callAPI = (e) => {
    e.preventDefault();
    const newUser = {
      username: document.getElementById("name").value.trim(),
      email: document.getElementById("email").value.trim(),
      gender: document.getElementById("gender").value.trim(),
      phone: document.getElementById("phone").value.trim(),
      college: document.getElementById("college").value.trim(),
      collegeid: document.getElementById("clgid").value.trim(),
      department: document.getElementById("dep").value.trim(),
      city: document.getElementById("city").value.trim(),
      state: document.getElementById("state").value.trim(),
      password: document.getElementById("password").value.trim(),
      conpassword: document.getElementById("conpassword").value.trim(),
    };
    this.props.registerUser(newUser, this.props.history);
  };
  render() {
    let errors = this.props.errors;

    let errordisplayName = "";
    let errordisplayEmail = "";
    let errordisplayGender = "";
    let errordisplayPhone = "";
    let errordisplayCollege = "";
    let errordisplayCollegeid = "";
    let errordisplayDepartment = "";
    let errordisplayCity = "";
    let errordisplayState = "";
    let errordisplayPassword = "";
    let errordisplayConPassword = "";
    let classesName = "formInput ";
    let classesEmail = "formInput ";
    let classesGender = "formInput2 Gender ";
    let classesPhone = "formInput2 phone ";
    let classesCollege = "formInput2 ";
    let classesCollegeid = "formInput2 ";
    let classesDepartment = "formInput ";
    let classesCity = "formInput2 ";
    let classesState = "formInput2 ";
    let classesPassword = "formInput2 confirmpass ";
    let classesConPassword = "formInput2 confirmpass ";

    if (errors.username) {
      classesName = classesName + "red-text-field";
      errordisplayName = <div className="error-message">{errors.username}</div>;
    }
    if (errors.email) {
      classesEmail = classesEmail + "red-text-field";
      errordisplayEmail = <div className="error-message">{errors.email}</div>;
    }
    if (errors.gender) {
      classesGender = classesGender + "red-text-field";
      errordisplayGender = <div className="error-message">{errors.gender}</div>;
    }
    if (errors.phone) {
      classesPhone = classesPhone + "red-text-field";
      errordisplayPhone = <div className="error-message">{errors.phone}</div>;
    }
    if (errors.college) {
      classesCollege = classesCollege + "red-text-field";
      errordisplayCollege = (
        <div className="error-message">{errors.college}</div>
      );
    }
    if (errors.collegeid) {
      classesCollegeid = classesCollegeid + "red-text-field";
      errordisplayCollegeid = (
        <div className="error-message">{errors.collegeid}</div>
      );
    }
    if (errors.department) {
      classesDepartment = classesDepartment + "red-text-field";
      errordisplayDepartment = (
        <div className="error-message">{errors.department}</div>
      );
    }
    if (errors.city) {
      classesCity = classesCity + "red-text-field";
      errordisplayCity = <div className="error-message">{errors.city}</div>;
    }
    if (errors.state) {
      classesState = classesState + "red-text-field";
      errordisplayState = <div className="error-message">{errors.state}</div>;
    }
    if (errors.password) {
      classesPassword = classesPassword + "red-text-field";
      errordisplayPassword = (
        <div className="error-message">{errors.password}</div>
      );
    }
    if (errors.conpassword) {
      classesConPassword = classesConPassword + "red-text-field";
      errordisplayConPassword = (
        <div className="error-message">{errors.password}</div>
      );
    }

    return (
      <div className="formOuter" style={{ backgroundColor: "#1e1762" }}>
        <SideDrawer />
        <div className="form">
          <form
              onSubmit={(e) => {
                ReactGa.event({
                  category: "Click",
                  action: "Clicked on Sign In in Signinpage",
                });
                this.callAPI(e);
              }} id="form" className="forminner">
            <div className="formInput heading">Create your KTJ account</div>
            <div className={classesName}>
              <FontAwesomeIcon icon={faUser} />
              <input
                id="name"
                type="text"
                placeholder="Name"
                required
              ></input>{" "}
            </div>
            {errordisplayName}

            <div className={classesEmail}>
              <FontAwesomeIcon icon={faEnvelope} />
              <input id="email" type="email" placeholder="Email"></input>
            </div>
            {errordisplayEmail}
            <div>
              <div className={classesPhone}>
                <FontAwesomeIcon icon={faPhoneAlt} />
                <input
                  type="text"
                  placeholder="Contact Number"
                  id="phone"
                ></input>
              </div>
              {errordisplayPhone}
              <div className={classesGender}>
                <FontAwesomeIcon icon={faPeopleArrows} />
                <input type="text" id="gender" placeholder="Gender"></input>
              </div>
              {errordisplayGender}
            </div>
            <div>
              <div className={classesCollege}>
                <FontAwesomeIcon icon={faGraduationCap} />
                <input type="text" id="college" placeholder="College"></input>
              </div>
              {errordisplayCollege}

              <div className={classesCollegeid}>
                <FontAwesomeIcon icon={faPen} />
                <input type="text" id="clgid" placeholder="College id"></input>
              </div>
              {errordisplayCollegeid}
            </div>
            <div className={classesDepartment}>
              <FontAwesomeIcon icon={faBook} />
              <input type="text" id="dep" placeholder="Department"></input>
            </div>
            {errordisplayDepartment}

            <div>
              <div className={classesCity}>
                <FontAwesomeIcon icon={faCity} />
                <input type="text" id="city" placeholder="City"></input>
              </div>
              {errordisplayCity}

              <div className={classesState}>
                <FontAwesomeIcon icon={faHotel} />
                <input type="text" id="state" placeholder="State"></input>
              </div>
              {errordisplayState}
            </div>
            <div>
              <div className={classesPassword}>
                <FontAwesomeIcon icon={faLock} />
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                ></input>
              </div>
              {errordisplayPassword}
            </div>
            <div>
              <div className={classesConPassword}>
                <FontAwesomeIcon icon={faLock} />
                <input
                  type="password"
                  id="conpassword"
                  placeholder="Confirm Password"
                ></input>
              </div>
              {errordisplayConPassword}
            </div>

            <button
              className="formSubmit"
              type="submit"
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>
    );
  }
}
Form.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Form));
