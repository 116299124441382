import React from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./Room.module.css";
import ReactGa from "react-ga";

const Room = (props) => {
  let enteranimationid;
  return (
    <>
      <div className={classes.Room} id={props.id}>
        <img src={props.bg} alt="" />
        <div
          className={classes.FrameContent}
          onClick={() => {
            ReactGa.event({
              category: "Click",
              action: "Clicked on " + props.name + " in Homepage Mobile",
            });
            props.click();
          }}
        >
          <video className={classes.Frame_main_gif} autoPlay muted loop>
            <source src={props.gif} type="video/webm" />
          </video>
          <div className={classes.Frame_Name}>{props.name}</div>
        </div>
      </div>
      <CSSTransition
        in={props.expanded}
        appear={props.expanded}
        timeout={1000}
        classNames="room_description"
        onEnter={(node) => {
          let height = document.getElementById(
            "FrameDescription" + props.frameNumber
          ).offsetHeight;
          document
            .getElementsByTagName("html")[0]
            .style.setProperty("--descheight", height + "px");
          const updatescroll = () => {
            if (
              node.previousElementSibling.offsetTop <=
                document.scrollingElement.scrollTop &&
              node.previousElementSibling.offsetTop >
                document.scrollingElement.scrollTop -
                  node.previousElementSibling.clientHeight / 2
            )
              window.scrollTo({
                left: 0,
                top: node.previousElementSibling.offsetTop,
              });
            else if (
              node.offsetTop + node.clientHeight >=
              document.scrollingElement.scrollTop +
                document.scrollingElement.clientHeight
            ) {
              window.scrollTo({
                left: 0,
                top:
                  node.offsetTop +
                  node.clientHeight -
                  document.scrollingElement.clientHeight,
              });
            }
            enteranimationid = window.requestAnimationFrame(updatescroll);
          };
          enteranimationid = window.requestAnimationFrame(updatescroll);
          setTimeout(() => {
            window.cancelAnimationFrame(enteranimationid);
          }, 1000);
        }}
      >
        <div style={{ position: "relative", overflow: "hidden" }}>
          <div
            className={classes.Frame_description}
            id={"FrameDescription" + props.frameNumber}
            key={props.frameNumber}
          >
            <div className={classes.Frame_second_gif}>
              <video autoPlay muted loop>
                <source src={props.circularvid} type="video/webm" />
              </video>
            </div>
            <div className={classes.Frame_content}>{props.children}</div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Room;
