import React, { Component } from "react";
import PropTypes from "prop-types";
import "./signinform.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactGa from "react-ga";

import SideDrawer from "../Components/SideDrawer/SideDrawer.js";
import { loginUser } from "../../../actions/authActions";

class signIn extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
      redirectToProfile: this.redirectToProfile.bind(this),
    };
  }

  redirectToProfile = () => {
    this.props.history.push("/profile");
  };

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        model: prevState.redirectToProfile(nextProps.model),
      };
    }
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }

  callAPI = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value.trim();
    const password = document.getElementById("password").value.trim();
    const userData = { email, password };
    this.props.loginUser(userData);
  };

  render() {
    let errors = this.props.errors;

    let errordisplayEmail = "";
    let errordisplayPassword = "";

    let classesEmail = "formInput ";
    let classesPassword = "formInput ";

    if (errors.email) {
      classesEmail = classesEmail + "red-text-field";
      errordisplayEmail = <div className="error-message">{errors.email}</div>;
    }
    if (errors.password) {
      classesPassword = classesPassword + "red-text-field";
      errordisplayPassword = (
        <div className="error-message">{errors.password}</div>
      );
    }

    return (
      <div className="outer" style={{ backgroundColor: "#1e1762" }}>
        <SideDrawer />
        <div className="signOuter">
          <form
              onSubmit={(e) => {
                ReactGa.event({
                  category: "Click",
                  action: "Clicked on Sign In in Signinpage",
                });
                this.callAPI(e);
              }} className="formOuter">
            <input
              type="text"
              className={classesEmail}
              placeholder="Email-ID"
              id="email"
            ></input>
            {errordisplayEmail}
            <input
              type="password"
              className={classesPassword}
              placeholder="Enter Password"
              id="password"
            ></input>
            {errordisplayPassword}
            <input
              type="submit"
              className="formSubmit"
              value="Sign In"
            ></input>
            <button
              className="formSubmit"
              onClick={() => {
                ReactGa.event({
                  category: "Click",
                  action: "Clicked on Forgot Password in Signinpage",
                });
                this.props.history.push("/resetpassword");
              }}
            >
              Forgot Password
            </button>
          </form>
        </div>
      </div>
    );
  }
}

signIn.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withRouter(signIn));
