import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import classes from "./signupMess.module.css";
import Navbar from "../../Components/Navbar/Navbar";

class signupMess extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  render() {
    return (
      <div className={classes.background} style={{backgroundColor: "#1e1762"}}>
        <Navbar />
        <div className={classes.msgWrapper}>
          <div className={classes.regLine}>You have been registered for</div>
          <div className={classes.ktjName}>
            KSHITIJ <span className={classes.year}>2021</span>.
          </div>
          <div className={classes.Idbox}>
            <span className={classes.idIntial}>Your KTJ-ID is </span>{" "}
            <span className={classes.ktjId}> {this.props.location.state.ktjID} </span>
          </div>
          <div className={classes.note}>
            Please note it for future reference
          </div>
          <div className={classes.explore}>Explore KTJ</div>
        </div>
      </div>
    );
  }
}

// SignIn.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(signupMess));
