import React, { Component } from "react";
import "./forgotpassword.css";
import "../vdoplayer/Vdo";
import bgvdo from "../../images/bgvdo.mp4";
import Vdo from "../vdoplayer/Vdo";
import ReactGa from "react-ga";

import { forgetPassword } from "../../actions/authActions";
import Navbar from "../Navbar/Navbar";

class ForgotPassword extends Component {
  submitform = () => {
    ReactGa.event({
      category: "Click",
      action: "Clicked on Forgot Password in forgotpasswordpage",
    });
    let email = document.getElementById("email").value;
    forgetPassword({ email });
  };
  render() {
    return (
      <div className="outer" style={{ backgroundColor: "#1e1762" }}>
        <Navbar styles={{ alignSelf: "flex-start" }} />
        <div className="signOuter">
          <form className="formOuter">
            <input
              type="text"
              className="Email"
              placeholder="Email-ID"
              id="email"
            ></input>
            <input
              type="submit"
              className="formSubmit"
              value="Forgot Password"
              id="submit"
              onClick={this.submitform}
            ></input>
          </form>
        </div>
        <Vdo name={bgvdo} />
      </div>
    );
  }
}

export default ForgotPassword;
