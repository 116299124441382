import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./signup.module.css";
import Vdo from "../../Components/vdoplayer/Vdo";
import bgvdo from "../../images/bgvdo.mp4";
import Form from "../../Components/Form/signupform";
import Navbar from "../../Components/Navbar/Navbar";
import { registerUser } from "../../actions/authActions.js";

class signup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
      redirectToProfile: this.redirectToProfile.bind(this),
    };
  }

  redirectToProfile = () => {
    this.props.history.push("/profile");
  };

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        model: prevState.redirectToProfile(nextProps.model),
      };
    }
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }

  callAPI = (e) => {
    e.preventDefault();
    const newUser = {
      username: document.getElementById("name").value.trim(),
      email: document.getElementById("email").value.trim(),
      gender: document.getElementById("gender").value.trim(),
      phone: document.getElementById("phone").value.trim(),
      college: document.getElementById("college").value.trim(),
      collegeid: document.getElementById("clgid").value.trim(),
      department: document.getElementById("dep").value.trim(),
      city: document.getElementById("city").value.trim(),
      state: document.getElementById("state").value.trim(),
      password: document.getElementById("password").value.trim(),
      conpassword: document.getElementById("conpassword").value.trim(),
    };
    this.props.registerUser(newUser, this.props.history);
  };
  render() {
    return (
      <div>
        <Navbar styles={{ alignSelf: "flex-start" }} />
        <Vdo name={bgvdo} />
        <Form errors={this.state.errors} function={this.callAPI} />
      </div>
    );
  }
}

// Just checking the type of the props component
signup.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(signup));
