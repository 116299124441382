import API from "./../api";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";

export const registerUser = (userData, history) => (dispatch) => {
  API
    .post("/register", userData)
    .then((res) => {
      if (res.data) {
        history.push({
          pathname: "/signupmessage",
          state: { ktjID: res.data.ktjID },
        });
        history.push("/signupmessage", res.data.ktjID);
      } else {
        return res.status(400).json("Unsucessful Login");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const loginUser = (userData, history) => (dispatch) => {
  API
    .post("/login", userData)
    .then((res) => {
      localStorage.setItem(
        "ktjUserLoginData",
        JSON.stringify(res.data.safeData)
      );
      dispatch(setCurrentUser(res.data.safeData));
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const logoutUser = (userData, history) => (dispatch) => {
  localStorage.removeItem("ktjUserLoginData");
  dispatch(setCurrentUser({}));
};

export const setCurrentUser = (userData) => {
  return {
    type: SET_CURRENT_USER,
    payload: userData,
  };
};

export const regForNewsletters = (email) => {
  API
    .post("/regForLetter/add", { email })
    .then(() => {
      alert("Succesfully registered!");
    })
    .catch((err) => {
      alert("Some error encountered! Please try again");
    });
};

export const forgetPassword = (email) => {
  API
    .post("/forgot_password", email)
    .then((res) => {
      alert(res.data);
    })
    .catch(() => {
      alert("Some error encountered! Please try again");
    });
};
