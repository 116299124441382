import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./signIn.module.css";
import SignInform from "../../Components/Form/signinform";
import Vdo from "../../Components/vdoplayer/Vdo";
import bgvdo from "../../images/bgvdo.mp4";
import Navbar from "../../Components/Navbar/Navbar";
import { loginUser } from "../../actions/authActions";

class SignIn extends React.Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
      redirectToProfile: this.redirectToProfile.bind(this),
    };
  }

  redirectToProfile = () => {
    this.props.history.push("/profile");
  };

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        model: prevState.redirectToProfile(nextProps.model),
      };
    }
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }

  callAPI = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value.trim();
    const password = document.getElementById("password").value.trim();
    const userData = { email, password };
    this.props.loginUser(userData);
  };

  render() {
    return (
      <div style={{backgroundColor: "#1e1762"}}>
        <Navbar styles={{ alignSelf: "flex-start" }} />
        <SignInform errors={this.state.errors} function={this.callAPI} />
        <Vdo name={bgvdo} />
      </div>
    );
  }
}

SignIn.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withRouter(SignIn));
