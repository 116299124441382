import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import KtjLogo from "../../assets/Images/ktjLogo.png";
import classes from "./SideDrawer.module.css";
import ReactGa from "react-ga";
import { logoutUser } from "../../../../actions/authActions";

class SideDrawer extends Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      drawerClass: [classes.SideDrawer_Drawer, classes.SideDrawer_Drawer_hide],
      errors: {},
    };
  }

  setShow = () => {
    if (this.state.show) {
      this.setState({
        drawerClass: [
          classes.SideDrawer_Drawer,
          classes.SideDrawer_Drawer_hide,
        ],
        show: false,
      });
    } else {
      this.setState({
        drawerClass: [
          classes.SideDrawer_Drawer,
          classes.SideDrawer_Drawer_show,
        ],
        show: true,
      });
    }
  };

  render() {
    const { isAuthenticated, userInfo } = this.props.auth;

    const logoutUser = (e) => {
      e.preventDefault();
      this.props.logoutUser(userInfo, this.props.history.push("/signin"));
    };
    return (
      <div
        className={classes.SideDrawer}
        onClick={(event) => {
          ReactGa.event({
            category: "Click",
            action:
              "Clicked the header " +
              (event.target.innerText ? event.target.innerText : "Sidedrawer"),
          });
        }}
      >
        <div className={classes.SideDrawer_logo}>
          <Link
            onClick={() => {
              window.scroll({ left: 0, top: 0, behavior: "smooth" });
            }}
            to="/"
          >
            {" "}
            <img src={KtjLogo} />
          </Link>
        </div>
        <div className={classes.SideDrawer_hmbrgIcon}>
          <i
            onClick={() => {
              this.setShow();
            }}
            className="fa fa-bars"
          ></i>
        </div>
        <div className={this.state.drawerClass.join(" ")}>
          <div className={classes.SideDrawer_Drawer_header}>
            {/* {!isAuthenticated ? (
              <div>
                <Link
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/signin"
                >
                  <button className={classes.SigninBtn}>Sign in</button>
                </Link>
                <Link
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/signup"
                >
                  <button className={classes.SignupBtn}>Sign Up</button>
                </Link>
              </div>
            ) : (
              <div>
                <Link
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/profile"
                >
                  <button className={classes.SigninBtn}>Profile</button>
                </Link>
                <Link onClick={logoutUser}>
                  <button className={classes.SignupBtn}>Logout</button>
                </Link>
              </div>
            )} */}
            <div>
              <i
                onClick={() => {
                  this.setShow();
                }}
                class="fas fa-angle-double-right"
              ></i>
            </div>
          </div>
          <div className={classes.SideDrawer_Drawer_Tabs}>
            <Link
              to="/schedule"
              style={{
                textDecoration: "none",
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "start",
                padding: "2rem",
              }}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
            >
              {" "}
              <div>Schedule</div>
            </Link>
            <Link
              to="/events"
              style={{
                textDecoration: "none",
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "start",
                padding: "2rem",
              }}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
            >
              {" "}
              <div>Events</div>
            </Link>
            <Link
              to="/activities/workshops"
              style={{
                textDecoration: "none",
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "start",
                padding: "2rem",
              }}
            >
              {" "}
              <div>Workshops</div>
            </Link>
            <Link
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
              to="/Activities"
              style={{
                textDecoration: "none",
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "start",
                padding: "2rem",
              }}
            >
              {" "}
              <div>Activities</div>
            </Link>
            <Link
              to="/sponsors"
              style={{
                textDecoration: "none",
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "start",
                padding: "2rem",
              }}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
            >
              {" "}
              <div>Sponsors </div>
            </Link>

            <Link
              to="/Contactus"
              style={{
                textDecoration: "none",
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "start",
                padding: "2rem",
              }}
              onClick={() => {
                window.scroll({ left: 0, top: 0, behavior: "smooth" });
              }}
            >
              {" "}
              <div>Contact Us</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

SideDrawer.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(SideDrawer));
